export const titleConfig = {
    xxlarge: {
        desktop: {
            fontSize: '74px',
            letterSpacing: '.09px',
            lineHeight: '80px',
        },
        tablet: {
            fontSize: '68px',
            letterSpacing: '.09px',
            lineHeight: '74px',
        },
        mobile: {
            fontSize: '62px',
            letterSpacing: '.12px',
            lineHeight: '66px',
        }
    },
    xlarge: {
        desktop: {
            fontSize: '56px',
            letterSpacing: '.16px',
            lineHeight: '64px',
        },
        tablet: {
            fontSize: '52px',
            letterSpacing: '.16px',
            lineHeight: '58px',
        },
        mobile: {
            fontSize: '48px',
            letterSpacing: '.04px',
            lineHeight: '52px',
        }
    },
    large: {
        desktop: {
            fontSize: '30px',
            letterSpacing: '.16px',
            lineHeight: '38px',
        },
        tablet: {
            fontSize: '28px',
            letterSpacing: '.16px',
            lineHeight: '36px',
        },
        mobile: {
            fontSize: '24px',
            letterSpacing: '.12px',
            lineHeight: '30px',
        }
    },
    medium: {
        desktop: {
            fontSize: '26px',
            letterSpacing: '.05px',
            lineHeight: '32px',
        },
        tablet: {
            fontSize: '24px',
            letterSpacing: '.05px',
            lineHeight: '28px',
        },
        mobile: {
            fontSize: '21px',
            letterSpacing: '.16px',
            lineHeight: '26px',
        }
    },
    small: {
        desktop: {
            fontSize: '21px',
            letterSpacing: '.62px',
            lineHeight: '24px',
        },
        tablet: {
            fontSize: '20px',
            letterSpacing: '.62px',
            lineHeight: '22px',
        },
        mobile: {
            fontSize: '18px',
            letterSpacing: '.62px',
            lineHeight: '24px',
        }
    },
    xsmall: {
        desktop: {
            fontSize: '18px',
            letterSpacing: '.62px',
            lineHeight: '22px',
        },
        tablet: {
            fontSize: '17px',
            letterSpacing: '.62px',
            lineHeight: '21px',
        },
        mobile: {
            fontSize: '16px',
            letterSpacing: '.62px',
            lineHeight: '20px',
        }
    },
    xxsmall: {
        desktop: {
            fontSize: '16px',
            letterSpacing: '.82px',
            lineHeight: '20px',
        },
        tablet: {
            fontSize: '15px',
            letterSpacing: '.82px',
            lineHeight: '18px',
        },
        mobile: {
            fontSize: '14px',
            letterSpacing: '.82px',
            lineHeight: '17px',
        }
    }
}

export const bodyConfig = {
    medium: {
        desktop: {
            fontSize: '18px',
            letterSpacing: '0.42px',
            lineHeight: '28px',
        },
        tablet: {
            fontSize: '18px',
            letterSpacing: '0.42px',
            lineHeight: '28px',
        },
        mobile: {
            fontSize: '17px',
            letterSpacing: '0.425px',
            lineHeight: '24px',
        }
    },
    small: {
        desktop: {
            fontSize: '16px',
            letterSpacing: '0.38px',
            lineHeight: '21px',
        },
        tablet: {
            fontSize: '16px',
            letterSpacing: '0.38px',
            lineHeight: '21px',
        },
        mobile: {
            fontSize: '16px',
            letterSpacing: '0.38px',
            lineHeight: '21px',
        }
    },
    xsmall: {
        desktop: {
            fontSize: '14px',
            letterSpacing: '0.32px',
            lineHeight: '18px',
        },
        tablet: {
            fontSize: '14px',
            letterSpacing: '0.32px',
            lineHeight: '18px',
        },
        mobile: {
            fontSize: '14px',
            letterSpacing: '0.325px',
            lineHeight: '18px',
        }
    }
  }