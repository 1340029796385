export const Colors = {

Purple 	 : '#6700E0',
BabyBlue : '#0092E0',
White 	 : '#FFFFFF',
Black		 : '#000000',
Red			 : '#FF0320',
Orange 	 : '#E08A00',
Aqua		 : '#03FFCF',
OffBlack : '#0D1112',
Grey		 : '#686868',
}

export default Colors;