export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  darkPurple: '#1A0043',
  darkestPurple: '#010017',
  orange: '#FF6C00',
  red: '#FF0000',
  pink: '#FF12D1',
  blue: '#004EFF',
  babyBlue: '#00BCF5',
  navyBlue: '#0A0085',
  purple: '#3308C4',
  gray: '#7F7F7F',
  darkGray: '#5f5f5f',
  grayLight: '#E0E0E0',
  'off-white': '#f8f8f8',
  error: '#FF0000'
}

export const gradients = {
  triColorGradient: 'linear-gradient(112deg, ' + colors.pink + ' -5%,' + colors.purple + ' 41%,' + colors.babyBlue + ' 100%)',
  orangeRed135: 'linear-gradient(135deg, ' + colors.orange + ' 0%,' + colors.red + ' 100%)',
  orangeRed: 'linear-gradient(90deg, ' + colors.orange + ' 0%,' + colors.red + ' 100%)',
  pinkPurple: 'linear-gradient(180deg, ' + colors.pink + ' 0%,' + colors.purple + ' 100%)',
  purpleGradient: 'linear-gradient(180deg, ' + colors.darkPurple + ' 0%,' + colors.darkestPurple + ' 100%)',
  purpleGradient135: 'linear-gradient(135deg, ' + colors.darkPurple + ' 0%,' + colors.darkestPurple + ' 100%)',
  lightBlueGradient: 'linear-gradient(135deg, ' + colors.babyBlue + ' 0%, #0070F2 100%)'
}